import './App.css';
import SearchPhotos from './components/searchPhotos';
import Navigation from './components/navigation/navigation';


function App() {
  
  return (
    <div className="App">
      <Navigation />
      <div className='imageBanner'>
      </div>
      <div className='test'>
      <div className="container">
        <div className='design'>
        <div class="designIam">
        <p>Text To Image</p>
        <b>
          <div class="innerIam">
            AI Tool<br /> 
            Visual Masterpieces<br />
            AI Render Images<br />
            Artistic<br />
            Creative
            </div>
        </b>
        </div>
        <div className='searchPhotos'>
            <SearchPhotos />
            </div>
      </div>
    </div>
    </div>
    </div>
  );
}

export default App;
